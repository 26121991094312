<template>
  <div>
    <v-dialog
      v-model="getDialog"
      @click:outside="handleClickOutSite"
      ref="dialog"
      max-width="464px"
      content-class="new-raido-popup "
    >
      <v-card>
        <v-card-title>
          <span>{{ $t('register_data.button_accept_request') }}</span>
        </v-card-title>
        <v-card-text v-if="notificationText">
          <span class="notification-text">
            {{ notificationText }}
          </span>
          <v-divider></v-divider>
        </v-card-text>
        <v-card-text>
        <v-radio-group v-model="actionApproval" hide-details>
          <v-list-item tabindex="1" v-for="item in items" :key="item.id" @keyup.13="selectMethod(item)" @keyup.32="selectMethod(item)">
            <v-list-item-action>
              <v-radio :ripple="false" :label="item.name" :value="item.id" color="success" class="">
                <template v-slot:label>
                  <div class="d-flex flex-column">
                    <div class="sub-title">{{ item.name }}</div>
                    <div class="sub-description">{{ item.description }}</div>
                  </div>
                </template>
              </v-radio>
            </v-list-item-action>
          </v-list-item>
        </v-radio-group>
        <v-divider></v-divider>
        <div class="dialog-comment">
          <div class="input-label">{{ $t('popup.title_log_confirm') }}</div>
          <v-textarea
            no-resize
            v-model="comment"
            hide-details
          ></v-textarea>
        </div>
      </v-card-text>
        <v-card-actions>
        <common-button class="left-button-popup" @action="close" :label=" $t('register_data.button_cancel')"/>
        <common-button type="redColor" @action="submit" :isDisable="disabledSubmit" :label="confirmText"/>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import CommonButton from '@/components/commonApp/Button.vue';
export default {
  components: {CommonButton},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: 'OK',
    },
    notificationText: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    disabledSubmit: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      itemSelect: null,
      actionApproval: 1,
      comment: null,
    };
  },
  mounted() {
  },
  computed: {
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },
  methods: {
    close() {
      this.$emit('close');
      this.comment = null;
      setTimeout(() => {
        this.actionApproval = 1;
      }, 1000);
    },
    submit() {
      const data = {
        status: this.actionApproval,
        comment: this.comment
      }
      this.$emit('submit', data);
      this.comment = null;
      setTimeout(() => {
        this.actionApproval = 1;
      }, 1000);
    },
    handleClickOutSite() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/components/popup/index.scss';
</style>