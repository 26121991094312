import moment from 'moment';
import i18n from '@/lang/i18n';

export const formatDateTime = (dateTime) => {
  if (dateTime) {
    return moment.utc(dateTime).add(9, 'hours').format('YYYY/M/DD HH:mm ') + 'JST';
  }
};
export const formatRegistrationDateDataTable = (year, month) => {
  if (year && month) {
    return moment(`${year}-${month}`, 'YYYY-MM').format('YYYY/MM');
  }
  return '';
};

export const formatDateTimeDataTable = (dateTime,noHour = false) => {
  if (dateTime) {
    if (noHour) {
      return moment.utc(dateTime).add(9, 'hours').format('YYYY/MM/DD');
    }
    return moment.utc(dateTime).add(9, 'hours').format('YYYY/MM/DD HH:mm:ss');
  }
};

export const formatNumber = (number, numberDecimals = 2) => {
  if (!number) {
    number = 0;
  }
  number = Number(number);
  if (Number.isInteger(number)) {
    return number.toLocaleString('ja-JP') + '.00';
  } else {
    const numberArr = number.toFixed(numberDecimals).split('.');
    const decimals = numberArr[1];
    return Number(numberArr[0]).toLocaleString('ja-JP') + '.' + decimals;
  }
};
export const formatDate = (value) => {
  const dateFormat = i18n.locale === 'en'
    ? 'YYYY/MM/DD' // Format for English
    : 'YYYY年M月D日 (ddd)'; // Format for other locales (e.g., Japanese)

  if (value != i18n.t('contractor.placeholder_select_date')) {
    value = String(value).split('-');
    value = moment(String(`${value[0]}/${value[1]}/${value[2]}`))
      .locale(i18n.locale)
      .format(dateFormat);
  }
  return value;
}

export const validRangeDate = (fromDate, toDate) => {
  // check valid date
  if (!moment(fromDate).isValid() || !moment(toDate).isValid()) {
    return true;
  }
  // check date range
  return !moment(toDate).isBefore(moment(fromDate));
};

export const generateYearsArray = (yearFrom, yearEnd) => {
  const yearsArray = [];
  for (let i = yearFrom; i <= yearEnd; i++) {
    yearsArray.push(i);
  }
  return yearsArray;
};

export const getMonthList = (postfix = '') => {
  const months = [];
  for (let i = 1; i < 13; i++) {
    months.push(i + postfix);
  }
  return months;
}

export const validateRangeData = (params) => {
  const {from, to, current} = params;
  if (from.year === null && from.month === null && to.year === null && to.month === null) return true;

  if (from.year === null) from.year = 2010;
  if (to.year === null) to.year = 2035;
  if (from.month === null) from.month = 1;
  if (to.month === null) to.month = 12;

  const currentDate = moment(`${current.year}-${current.month}`);
  const fromDate = moment(`${from.year}-${from.month}`);
  const toDate = moment(`${to.year}-${to.month}`);

  // check current date is between from date and to date
  if (currentDate.isSameOrAfter(fromDate) && currentDate.isSameOrBefore(toDate)) return true;
  return false;
}

export const rangeDateFormat = (params) => {
  const { year, month } = params;
  let value = '';
  if (year && month) {
    value = moment(`${year}/${month}`).format(momentFormat({
      year: year, month: month
    }));
  }
  if (year && !month) {
    value = moment(`${year}`).format(momentFormat({
      year: year, month: month
    }));
  }
  if (!year && month) {
    value = moment(`${month}`).format(momentFormat({
      year: year, month: month
    }));
  }
  return value;
}

const momentFormat = (params) => {
  const { year, month } = params;
  if (month && year) return i18n.locale === 'ja' ? 'YYYY年MM月' : 'YYYY/MM';
  if (month && !year) return i18n.locale === 'ja' ? 'MM月' : 'MM';
  if (!month && year) return i18n.locale === 'ja' ? 'YYYY年' : 'YYYY';
}

export const formatDateRangeDashboard = (params) => {
  const { start_at, end_at } = params;
  let startDate = moment(start_at, "YYYY-MM");
  let endDate = moment(end_at, "YYYY-MM");
  return `${startDate.format(i18n.t('new_cfp.label_fy_yyyy_month_mm_new_dashboard'))} - ${endDate.format(i18n.t('new_cfp.label_fy_yyyy_month_mm_new_dashboard'))}`;
}

export const formatDateRangeNewDashboardChart = (params) => {
  const { start_at, end_at } = params;
  let startDate = moment(start_at, "YYYY-MM");
  let endDate = moment(end_at, "YYYY-MM");
  return `${startDate.format("YYYY/M")} - ${endDate.format("YYYY/M")}`;
}
export const unFormatAndCheckValidRangeDate = (from, to, formatDate) => {
  const fromDate = moment(from, formatDate).format('YYYY/MM');
  const toDate = moment(to, formatDate).format('YYYY/MM');
  return moment(fromDate) - moment(toDate);
};

export default {
  formatDateTime,
  formatNumber,
  formatDate,
  validRangeDate,
};
